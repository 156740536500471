import { useParams } from "react-router-dom";
import { BottomSection } from "./BottomSection";
import { MiddleSection } from "./MiddleSection";
import { TopSection } from "./TopSection";
import { NotFound } from "../screen/NotFound";
import { handler } from "../service/handler";
import { useEffect, useState } from "react";
import { Mosaic } from "react-loading-indicators";

export function Card() {
  const { id } = useParams();
  const service = handler();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [foto, setFoto] = useState(null)

  useEffect(() => {
    // service
    //   .getAnagrafica(id)
    //   .then(({ result, photoRes }) => {
    //     setData(result);
    //     setFoto(photoRes.data)
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setData(service.dataBeautifier(mockData, mockCommonData));
    //     setLoading(false);
    //     console.log(err);
    //     //toast.error(err.response ? err.response.data.userMessage : err.message);
    //   });
    handleData();
  }, [id]);

  const handleData = async () => {
    let result = await service.getAnagrafica(id);
    setData(result);
    if (result) {
      let photoRes = await service.getFoto(result);
      if (photoRes) {
        setFoto(photoRes);
      }
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Mosaic color="#008DD9" size="medium" text="" textColor=""/>
      </div>)
  }

  return (
    <div style={{ height: "100%" }}>
      <TopSection data={data} foto={foto} />
      <MiddleSection state={data} />
      <BottomSection state={data} />
    </div>
  );
}

const mockData = [
  {
    anagrafica: {
      Item: {
        Telefono_cellulare: "",
        Telegram: "",
        Indirizzo_Mail: "m.muti@ntditalia.com",
        Sito_azienda: "ntditalia.com",
        Azienda: "Ntd Italia",
        Foto: "33651a7f-f525-4eb6-b677-fede7858682a.png",
        Linkedin: "www.linkedin.com/in/mattiamuti/",
        Telefono_fisso: "",
        Posizione: "SAP Full Stack BTP Developer",
        Indirizzo_Sede: "7bee35a9-1c75-4312-880f-6576a506705d",
        Id: "2a119c43-40e9-4839-bc49-a4058a378d48",
        Nominativo: "Muti Mattia",
      },
    },
  },
  {
    indirizzi: {
      Items: [
        {
          CAP: "00148",
          Provincia: "RM ",
          Nazione: "Italia",
          Sede_Principale: true,
          Citta: "Roma",
          Id: "7bee35a9-1c75-4312-880f-6576a506705d",
          Indirizzo: "Via Cesare Giulio Viola 19",
        },
        {
          CAP: "20123",
          Provincia: "MI",
          Nazione: "Italia",
          Sede_Principale: false,
          Citta: "Milano",
          Id: "c8f1f988-dc5c-442e-b79c-198a84bc2e6a",
          Indirizzo: "Via Santa Maria Valle 3",
        },
      ],
      Count: 2,
      ScannedCount: 2,
    },
  },
];

const mockCommonData = [
  {
    anagrafica: {
      Item: {
        Telefono_cellulare: "+39 0632541503",
        Telegram: "Telegram",
        Indirizzo_Mail: "info@ntditalia.com",
        Sito_azienda: "ntditalia.com",
        Azienda: "NTD Italia",
        Foto: "Foto",
        Linkedin: "www.linkedin.com/company/ntd-italia/mycompany/",
        Telefono_fisso: "+39 0632541503",
        Posizione: "Posizione",
        Indirizzo_Sede: "7bee35a9-1c75-4312-880f-6576a506705d",
        Id: "da74f05e-77c1-4fb3-afb5-9b15616a0e88",
        Nominativo: "Nominativo",
      },
    },
  },
  {
    indirizzi: {
      Items: [
        {
          CAP: "00148",
          Provincia: "RM ",
          Nazione: "Italia",
          Sede_Principale: true,
          Citta: "Roma",
          Id: "7bee35a9-1c75-4312-880f-6576a506705d",
          Indirizzo: "Via Cesare Giulio Viola 19",
        },
        {
          CAP: "20123",
          Provincia: "MI",
          Nazione: "Italia",
          Sede_Principale: false,
          Citta: "Milano",
          Id: "c8f1f988-dc5c-442e-b79c-198a84bc2e6a",
          Indirizzo: "Via Santa Maria Valle 3",
        },
      ],
      Count: 2,
      ScannedCount: 2,
    },
  },
];
