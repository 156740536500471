import './App.css';
import { Navigator } from './screen/Navigator';

function App() {
  return (
    <div style={{height: '100%'}}>
      <Navigator/>
    </div>
  );
}

export default App;
