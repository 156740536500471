import axios from "axios";
export function handler(){

    const host = process.env.REACT_APP_API_URL;
    const common = process.env.REACT_APP_COMMON_DATA_RECORD;
    const bucket = process.env.REACT_APP_BUCKET;

    const getAnagrafica = async (id) => {
        try {
            let url = host + "/anagrafica_dipendenti?Id=";
            let res = await axios(url + id);
            let commonRes = await axios(url + common);
            if (res.data[0].anagrafica.Item){
                let result = dataBeautifier(res.data, commonRes.data)
                return result
            }
            return null
        } catch (error) {
            console.log(error);
            throw new Error(error)
        }
    }

    const getFoto = async (result) => {
        try {
            let photoUrl = host + `/get-image?Bucket=${bucket}&Key=${result.Foto}`;
            let photoRes = await axios.get(photoUrl, { 
                headers: { id: "Admin", password: "admin" },
                responseType: 'arraybuffer' 
            });
            // Converte l'array di byte in un oggetto Blob
            const blob = new Blob([photoRes.data], { type: photoRes.headers['content-type'] });

            // Crea un URL per il Blob e utilizzalo per visualizzare l'immagine
            const imageUrl = URL.createObjectURL(blob);
            return imageUrl
        } catch (err) {
            console.log(err)
        }
    }

    const dataBeautifier = (res, commonRes) => {
        var userAnag = res[0].anagrafica.Item;
        var commonAnag = commonRes[0].anagrafica.Item;

        const getIndirizzo = (idIndirizzo, indirizzi) => {
            try {
                var oData = indirizzi.find(x => x.Id === idIndirizzo);
                return oData.Indirizzo + ", " + oData.CAP + ", " + oData.Citta;
            } catch (error) {
                return ""
            }
        }

        var obj = {
            Nome: userAnag.Nominativo,
            Posizione: userAnag.Posizione,
            Azienda: userAnag.Azienda,
            TelefonoCell: userAnag.Telefono_cellulare || commonAnag.Telefono_cellulare,
            TelefonoFisso: userAnag.Telefono_fisso || commonAnag.Telefono_fisso,
            Mail: userAnag.Indirizzo_Mail || commonAnag.Indirizzo_Mail,
            Sito: userAnag.Sito_azienda || commonAnag.Sito_azienda,
            Linkedin: userAnag.Linkedin || commonAnag.Linkedin,
            Telegram: userAnag.Telegram || commonAnag.Telegram,
            Foto: userAnag.Foto,
            Indirizzo: getIndirizzo(userAnag.Indirizzo_Sede, res[1].indirizzi.Items),
        }
        return obj
    }

    return{
        getAnagrafica,
        getFoto,
    }
}