import phone from "../assets/phone_full.svg"
import mail from "../assets/mail_full.svg"
import web from "../assets/web_full.svg"
import { SocialButton } from "./SocialButton"
import vCardsJS from "vcards-js";

export function MiddleSection({ state }) {
    const downloadTxtFile = (vcfText) => {
        const element = document.createElement("a");
        const file = new Blob([vcfText], { type: "text/vcard;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = `${state["Nome"]}.vcf`;
        document.body.appendChild(element);
        element.click();
    };
    console.log(state)
    const CreateVCard = () => {
        var vCard = vCardsJS();
        vCard.firstName = state["Nome"].split(" ")[0];
        vCard.lastName = state["Nome"].split(" ")[1];
        vCard.title = state["Posizione"];
        vCard.organization = state["Azienda"];
        vCard.url = state["Linkedin"];
        vCard.workEmail = state["Mail"]
        vCard.workAddress.street = state["Indirizzo"]; 
        vCard.workPhone = `+${state["TelefonoFisso"].slice(0,2)}${state["TelefonoFisso"].slice(-10)}`;
        if (state["TelefonoFisso"] != state["TelefonoCell"]) {
            let strCell = state["TelefonoCell"].toString();
            vCard.cellPhone = `+${strCell.slice(0,2)}${strCell.slice(-10)}`;
        }
        return vCard.getFormattedString();
    };
    // const addContact = async () => {
    //     let tel = "";
    //     if (state["TelefonoCell"] !== state["TelefonoFisso"]) {
    //         const telFields = [
    //             `TEL;type=CELL;type=VOICE;type=pref:${state["TelefonoCell"]}`,
    //             `TEL;type=WORK;type=VOICE:${state["TelefonoFisso"]}`,
    //         ];
    //         tel = telFields.join("\n");
    //     } else {
    //         tel = `TEL;type=WORK;type=VOICE;type=pref:${state["TelefonoFisso"]}`;
    //     }
    //     var file = new Blob(
    //         [
    //             `BEGIN:VCARD
    // VERSION:3.0
    // N:${state["Nome"]};;;;
    // FN:${state["Nome"]} - NTD ITALIA
    // TITLE:${state["Posizione"]}
    // EMAIL;type=INTERNET;type=pref:${state["Mail"]}
    // ${tel}
    // ADR;type=WORK;type=pref:;;;${state["Indirizzo"]};;;
    // ORG:${state["Azienda"]}
    // URL:${state["Linkedin"]}
    // END:VCARD`,
    //         ],
    //         { type: "text/vcard;charset=utf-8" }
    //     );
    //     let a = document.createElement("a");
    //     let blobURL = URL.createObjectURL(file);
    //     a.download = `${state["Nome"]} - NTD ITALIA.vcf`;
    //     a.href = blobURL;
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    // }

    return (
        <div style={styles.container}>
            {state ?
                <div>
                    <div style={styles.buttonContainer} onClick={() => downloadTxtFile(CreateVCard())}>
                        <button style={styles.button}>Salva Contatto</button>
                    </div>

                    <div style={styles.sectionContainer}>
                        <div style={styles.section}>
                            <img alt="phone" src={phone} style={{ ...styles.icon }} />
                            <div>{state["TelefonoCell"].toString().slice(-10) || 
                            state["TelefonoFisso"].slice(-10)}</div>
                        </div>
                        <div style={styles.section}>
                            <img alt="mail" src={mail} style={{ ...styles.icon }} />
                            <div>{state["Mail"]}</div>
                        </div>
                        <div style={styles.section}>
                            <img alt="website" src={web} style={{ ...styles.icon }} />
                            <div>{state["Sito"]}</div>
                        </div>
                    </div>
                </div> :
                <div style={styles.errorSection}>
                    <div>L'id del dipendente è mancante o non corretto. Ti preghiamo di riprovare.</div>
                </div>}
            {state && <SocialButton data={{
                linkedin: state["Linkedin"],
                telegram: state["Telegram"],
                whatsapp: state["TelefonoCell"]
            }} />}
        </div>
    )
}

const styles = {
    container: {
        width: '100%',
        height: '40%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        // padding: '5% 0 2% 0',
        justifyContent: 'space-between'
    },
    buttonContainer: {
        width: '100%',
        maxWidth: 200,
        marginBottom: '10%',
        marginTop: '10%'
    },
    button: {
        width: '100%',
        height: '2.5rem',
        backgroundColor: '#06c',
        borderRadius: 10,
        border: 'none',
        color: 'white',
        fontSize: '1.1rem',
        fontWeight: 400,
        cursor: 'pointer',
        boxShadow: '0 10px 10px rgba(0,0,0,.08)',
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    section: {
        display: 'flex',
        marginBottom: 10,
        alignItems: 'center'
    },
    errorSection: {
        fontSize: 20,
        maxWidth: "80%",
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    icon: {
        width: 25,
        height: 25,
        marginRight: 10
    }
}