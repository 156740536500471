import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Card } from '../components/Card';

export function Navigator(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Card/>} />
                <Route path="/:id" element={<Card/>} />
            </Routes>
        </BrowserRouter>

    )
}