import telegram from "../assets/telegram.svg"
import whatsapp from "../assets/whatsapp.svg"
import linkedin from "../assets/linkedin.svg"

export function SocialButton({ data = {} }) {
    return (
        <div style={styles.container}>
            <div style={styles.text}>Let's keep in touch!</div>
            <div style={styles.socialContainer}>
                {data.linkedin &&
                    <a href={`https://${data.linkedin}`} target="_blank">
                        <div style={styles.imgContainer}>
                            <img alt="button" src={linkedin} style={styles.image} />
                        </div>
                    </a>
                }
                {data.whatsapp &&
                    <a href={`https://wa.me/${data.whatsapp}`}>
                        <div style={styles.imgContainer}>
                            <img alt="button" src={whatsapp} style={styles.image} />
                        </div>
                    </a>
                }
                {data.telegram !== "Telegram" &&
                    <a href={`https://t.me/${data.telegram}`}>
                        <div style={styles.imgContainer}>
                            <img alt="button" src={telegram} style={styles.image} />
                        </div>
                    </a>
                }
            </div>
        </div>
    )
}
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 2
    },
    socialContainer: {
        width: '100%',
        // height: '15%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        maxWidth: '250px',
        maxHeight: '60px',
    },
    text: {
        marginBottom: 15,
        fontSize: '1.2rem'
    },
    imgContainer: {
        width: 40,
        height: 40,
        borderRadius: 10,
    },
    image: {
        width: '100%',
        height: '100%',
    }
}