import bottomWave from "../assets/waveAbove.svg"
import logo from "../assets/logo.png"
export function BottomSection({state}){
    const topWaveBackgroundStyle = {
        backgroundImage: `url(${bottomWave})`,
        backgroundSize: '128% 228px',
        // backgroundSize: '128% 228px',
        backgroundPosition: '50% 40%'
    }

    return(
        <div style={styles.container}>
            <div style={{...styles.bottomWaveContainer}}>
                <img alt="test" src={bottomWave} style={styles.bottomWave}></img>
            </div>
            <div style={styles.contentArea}>
                <div style={styles.leftArea}>
                    <img alt="logo" src={logo} style={styles.logo}></img>
                </div>
                <div style={styles.rightArea}>
                    <div style={styles.address}>
                        {state ? state.Indirizzo : "Via Cesare Giulio Viola 19, 00148, Roma"}
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    container: {
        background: 'linear-gradient(195deg,#0cc 27%,#06c 74%)',
        width: '100%',
        height: '20%',
        position: 'relative'
    },
    bottomWaveContainer:{
        width: '100%',
        height: '100%',
        display: 'flex',
        flex: 1,
        alignItems: 'end',
        justifyContent: 'center',
        position: 'absolute',
        top: '-1px'
    },
    bottomWave:{
        width: '100%',
        height: '100%'
    },
    contentArea:{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
    },
    leftArea:{
        width: '50%',
        height:'100%',
        display: 'flex',
        alignItems: 'flex-end'
    },
    rightArea:{
        height:'100%',
        display: 'flex',
        flex: 1,
        justifyContent: 'end',
    },
    logo:{
        width: 50,
        height: 40,
        padding: '1rem'
    },
    address:{
        padding: '1rem',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'end',
        color: 'white',
        opacity: 0.8,
        textAlign: 'end',
        fontSize: '0.9rem'
    }

}