import { ReactComponent as WaveAbove } from "../assets/waveAbove.svg"
import { ReactComponent as WaveBelow } from "../assets/waveBelow.svg"
import topWave from "../assets/waveBelow.svg"
import test from "../assets/download.png"
import phone from "../assets/phone.png"
import mail from "../assets/mail.png"
import web from "../assets/web.png"
import { RoundedButton } from "./RoundedButton"


export function TopSection({data, foto}){
    console.log("state top", foto)
    const topWaveBackgroundStyle = {
        backgroundImage: `url(${topWave})`,
        backgroundSize: '128% 20vh',
        // backgroundSize: '128% 228px',
        backgroundPosition: '50% 80%'
    }

    const handleCall = () => {
        
    }

    return (
        <div style={styles.container}>
            <div style={styles.personImageContainer}>
                {foto && <img alt="test" src={foto} style={styles.personImage}></img>}
            </div>
            <div style={styles.personName}>
                {data && data.Nome}
            </div>
            <div style={styles.personRole}>
                {data && data.Posizione}
            </div>
            <div style={{...styles.topWaveContainer, ...topWaveBackgroundStyle}}>
                <div style={styles.buttonContainer}>
                    {data && <RoundedButton url={`tel://${data.TelefonoCell ? data.TelefonoCell : data.TelefonoFisso}`} icon={phone}/>}
                    {data && <RoundedButton url={`mailto:${data.Mail}`} icon={mail}/>}
                    {data && <RoundedButton url={`https://${data.Sito}`} target="_blank" icon={web}/>}
                </div>
            </div>
        </div>
    )
}
const styles = {
    container:{
        background: 'linear-gradient(195deg,#0cc 27%,#06c 74%)',
        width: '100%',
        height: '40%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative'
    },
    personImageContainer:{
        width:'100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50%',
        maxWidth: '220px'
    },
    personImage:{
        height:'100%',
        width: '100%',
        objectFit: 'contain'
    },
    personName:{
        color: 'white',
        fontSize: '1.5rem',
        margin: '8px 0 2px 0',
        fontWeight: 400,
        height: '10%',
        textAlign: 'center'
    },
    personRole:{
        color: 'white',
        fontSize: '1rem',
        opacity: 0.8,
        fontWeight: 300,
        height: '10%',
        textAlign: 'center',
        paddingBottom: 10
    },
    topWaveContainer:{
        width: '100%',
        display: 'flex',
        flex: 1,
        alignItems: 'end',
        justifyContent: 'center',
        height: '20%',
        position: 'absolute',
        bottom: '-1px'
    },
    buttonContainer:{
        display: 'flex',
        justifyContent: 'space-between',
        width:'100%',
        height:'100%',
        maxWidth: '250px',
        maxHeight: '60px',
    }
}