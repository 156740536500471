export function RoundedButton({icon, url, target = "", pressCallback, size='50px'}){
    return(
        <a href={url} target={target}>
            <div style={{...styles.container, maxHeight: size}} onClick={pressCallback}>
                <img alt="button" src={icon}  style={{...styles.icon}}/>
            </div>
        </a>
    )
}
const styles = {
    container:{
        borderRadius: '50%',
        boxShadow: '0 10px 10px rgba(0,0,0,.08)',
        cursor: 'pointer',
        // maxWidth: '30%'
        // backgroundColor: 'red',
        // border: 'none'
    },
    icon:{
        color: 'transparent',
        width: '100%',
        height: '100%',
    }
}